import React from "react";
import Home from "./home/index.page";

const index = () => {
    return (
        <div className="h-full w-full bg-gray-5">
            <span className="test-vercel-cache"></span>
            <Home />
        </div>
    );
};

export default index;

export const getStaticProps = async () => {
    return {
        props: {},
    };
};
